<script>
import moment from "moment";

export default {
  locales: {
    pt: {
      'My Investment': 'Meu Investimento',
      'My Profit': 'Meu Rendimento',
      'My Team': 'Minha Equipe',
      'Monthly': 'Ativação',
      'Total Bonus': 'Total de Bônus',
      'Current Balance': 'Saldo Atual',
      'Graduation': 'Graduação',
      'No Graduation': 'Sem Graduação',
      'Points': 'Pontos',
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INATIVO',
    },
    es: {
      'My Investment': 'Mi Inversion',
      'My Profit': 'Mi Rendimiento',
      'My Team': 'Mi Equipo',
      'Monthly': 'Ativação',
      'Total Bonus': 'Total de Bonus',
      'Current Balance': 'Saldo Actual',
      'Graduation': 'Graduación',
      'No Graduation': 'No Graduación',
      'Points': 'Puntos',
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INATIVO',
    }
  },
  props: {
    type: {
      type: String,
      default: "default"
    },
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    month: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      monthly: moment().format("MM/YY"),
    }
  }
};
</script>

<template>
  <div class="card bg-white">
    <div class="card-body p-3">
      <div v-if="type == 'line'" class="d-flex align-items-center">
        <div>
          <div class="bg-light rounded py-1 px-2">
            <i class="bx font-size-24 mt-1" :class="icon"></i>
          </div>
        </div>
        <div class="flex-fill ml-2">
          <h6 v-if="value == 'active'" class="mb-0 notranslate font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h6>
          <h6 v-else-if="value == 'inactive'" class="mb-0 notranslate font-weight-bold"><span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span></h6>
          <h5 v-else class="mb-0 notranslate font-weight-bold">{{ t(value) }}</h5>
          <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12" v-html="t(title)"></p>
        </div>
        <div v-if="month" class="text-right">
          <div class="font-size-11 mb- bg-soft-light rounded p-1">
            {{ monthly }}
          </div>
        </div>
      </div>
      <div v-else-if="type == 'center'" class="text-center align-items-center">
        <h5 v-if="value == 'active'" class="mb-0 notranslate font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'inactive'" class="mb-0 notranslate font-weight-bold"><span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else class="mb-0 notranslate font-weight-bold">{{ t(value) }}</h5>
        <p class="font-weight-medium mb-0 text-uppercase font-weight-light" v-html="t(title)"></p>
      </div>
      <div v-else class="d-flex justify-content-between align-items-center">
        <p class="font-weight-medium mb-0 text-uppercase font-weight-light" v-html="t(title)"></p>
        <h5 v-if="value == 'active'" class="mb-0 notranslate font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'inactive'" class="mb-0 notranslate font-weight-bold"><span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else class="mb-0 notranslate font-weight-bold">{{ t(value) }}</h5>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg {
  background-color: #e0e0e0;
}
p {
  letter-spacing: 1px;
}
</style>